import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import SearchProduct from '../simple/SearchProduct';
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook';

const SearchInput = observer(() => {
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const [term, setTerm] = useState('');

    const {
        items,
        setFilterData
    } = useFetchItems(CatalogServices.products.index)

    const dynamicSearch = (e) => {
        setTerm(e.target.value);
        if(e.target.value.length >= 3){
            setFilterData(`?term=${term}`)
        }
        if(e.target.value.length <= 0){
            setFilterData(``)
        }
    }

  return (
    <div className='droupdown-search' ref={dropdownRef}>
        <div className='input-wrapper' onClick={() => setIsActive(true)}>
            <input type="text" className='input' placeholder='Поиск' value={term} onChange={(e) => dynamicSearch(e)}/>
            <span>
                <i className='icon-search-1'></i>
            </span>
        </div>
        <div className={isActive ? 'droupdown-search-content visible' : 'droupdown-search-content'}>
            {items?.items?.map((card, index) =>
                <SearchProduct card={card} key={index}/>
            )}
        </div>
    </div>
  )
})

export default SearchInput