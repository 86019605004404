import Login from "../pages/auth/Login";
import CheckoutPage from "../pages/checkout/CheckoutPage";
import MainPage from "../pages/main/MainPage";
import SamplePage from "../pages/sample/SamplePage";
import Profile from "../pages/user/Profile";
import { CHECKOUT_PAGE_ROUTE, LOGIN_PAGE_ROUTE, MAIN_PAGE_ROUTE, SAMPLE_PAGE_SOUTE, USER_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: CheckoutPage
    },
    {
        path: USER_PAGE_ROUTE,
        Component: Profile
    },
    {
        path: LOGIN_PAGE_ROUTE,
        Component: Login
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },

]
