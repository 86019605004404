import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            lang: 'Язык',
            phone_call: '',
            stock: 'Акции',
            tel: 'Тел',
            email: 'Почта',
            address: 'Адрес',
            currency: 'тг',
            to_cart: "В корзину",
            availability: "Доступность",
            in_stock: "В наличие",
            not_available: "Нет в наличие",
            to_cart_for: "В корзину за",
            phone: 'Номер телефон',
            name: 'Имя',
            bonuses: 'Бонусы',
            wishlist: 'Избранное',
            my_orders: 'Мои заказы',
            history: 'История',
            view_all: 'Смотреть все',
            welcome_profile: 'Привет,',
            dashboard: 'Профиль',
            all_addresses: 'Адреса доставки',
            profile: 'Персональные данные',
            birthday: 'День рождения',
            gender: 'Пол',
            edit: 'Редактировать',
            delete_profile: 'Удалить профиль',
            active_orders: 'Активные',
            order: 'Заказ',
            add_address: 'Добавить адрес',
            delivery_payment: 'Доставка и оплата',
            order_date: 'Дата',
            delivery_variant: 'Вариант доставки',
            message: 'Комментарий к заказу',
            order_list: 'Состав заказа',
            total: 'Всего',
            discount: 'Скидка',
            login: 'Войти',
            profile_edit: 'Редактирование профиля',
            bonus_history: 'История бонусов',
            logout: 'Выйти',
            save: 'Сохранить',
            repeat_order: 'Повторить заказ',
            code: 'Код подтверждения',
            to_order: 'Перейти к заказу',
            bonus_gained: 'Оплачено бонусами',
            sub_total: 'Промежуточный итог',
            discount_price: 'Скидка',
            delivery_price: 'Цена доставки',
            delivery_address: 'Адрес доставки',
            choose_another: 'Выбрать другой',
            entrance: 'Подъезд',
            floor: 'Этаж',
            apartment: 'Квартира',
            code_domofon: 'Код домофона',
            female: 'жен',
            male: 'муж',
            success_update_profile: 'Данные профиля успешно обновлены',
            pick_up_point: 'Пункт выдачи',
            payment_variant: "Способ оплаты",
            your_order: "Ваш заказ",
            spend_bonuses: "Потратить бонусы",
            bonuses_accumulated: "бонусов накоплено",
            used_bonuses: "Использовано бонусов",
            delivery: "Доставка",
            checkout_order: "Оформить заказ",
            user_data: "Данные пользователя",
            new_address: "Новый адрес",
            success_add_address: "Адрес добавлен",
            cart: "Корзина",
            delete_profile_content: 'Вы уверен, что хотите удалить свой профиль?',
            cancel: 'Отмена',
            delete: 'Удалить',
            select: 'Выбрать',
            pick_up_selected: 'Пункт выдачи выбран',
            address_success_update: 'Адрес изменен',
            product_to_cart_success: 'Товары успешно добавлены в корзину!',
            cabinet: 'Кабинет',
            street: 'Улица',
            apply: 'Применить',
            app_title: 'В приложении удобнее',
            app_content: 'Скачивайте наше приложение в App Store и Google play',
            menu: 'Меню',
            contacts: 'Контакты',
            checkout_ordering: 'Оформление заказа',
            get_bonuses: 'Будет начислено',
            get_bonuses_content: 'Бонусы будут начислены после получения товара',
            free_delivery_amount: 'До бесплатной доставки осталось',
            no_active_orders: 'У вас нет активных заказов',
            empty_data: 'Данных не обнаружено',
            entry: "Вход",
            login_content: "Мы отправим Вам смс с кодом подтверждения на телефон",
            get_code: "Получить код",
            promocode: "Промокод",
            promo_discount_content: "Скидка по промокоду",
            promo_product_content: "Товар по промокоду",
            promo_delivery_content: "Доставка по промокоду",
            free: "Бесплатно",
            error_title: "Не удалось найти страницу",
            error_content: "Страница, которую вы ищете, не может быть найдена. Ссылка на этот адрес может быть устаревшей или мы могли переместить ее с тех пор, как вы последний раз добавляли ее в закладки.",
            error_button: "Вернуться на главную",
            footer_title: 'EaseFood',
            footer_bottom_title: "EaseFood.kz",
            thanks: 'Спасибо',
            not_accent_orders: "Временно не принимаем заказы на сайте и в мобильном приложений, приносим свои извинения",
        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._registerByPhone = false
        makeAutoObservable(this)
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}