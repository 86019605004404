import { observer } from 'mobx-react-lite';
import React from 'react'
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../..';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import UserServices from '../../services/general/userServices';
import Utils from '../../services/utils';
import OrderDetailCard from '../simple/OrderDetailCard';
import { toast } from 'react-toastify';
import img from "../../images/placeholder.png"

const OrderProductDetail = observer(() => {
    const {id} = useParams();

    const {app, user} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.orders.view, id);

    const repeatOrder = async () => {
        const res = await UserServices.cart.repeatOrder(info.id);

        if(res.statusCode === 200){
            toast.success(app.localizationsItems?.product_to_cart_success);
            user.setBasketChange(Math.random().toString(16))
        }else{
            toast.error(res.message)
        }
    }

  return (
    <div className='profile-dashboard'>
        {loading &&
            <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
        }
        {info &&
            <>
                <div className='flex'>
                    <div>
                        <h3>{app.localizationsItems?.order} №{info.number}</h3>
                        {info.status && <div className='order-card-state' style={{background: info.status.color}}>{info.status.name}</div>}
                    </div>
                    <button className='main-btn accent-btn' onClick={repeatOrder}>{app.localizationsItems?.repeat_order}</button>
                </div>
                <div className="order-card">
                    <h5>{app.localizationsItems?.delivery_payment}</h5>
                    <ul className='order-list'>
                        <li>{app.localizationsItems?.order_date}: <span>{info.created_at && Utils.generateOrderDate(info.created_at)}</span></li>
                        <li>{app.localizationsItems?.address}: <span>{info.address?.full_address}</span></li>
                        <li>{app.localizationsItems?.delivery_variant}: <span>{info.delivery?.name}</span></li>
                        <li>{app.localizationsItems?.payment_variant}: <span>{info.payment.name}</span></li>
                        <li>{app.localizationsItems?.message}: <span>{info.description}</span></li>
                    </ul>
                </div>
                <div className="order-card">
                    <h5>{app.localizationsItems?.order_list}</h5>
                    <div className='address-grid'>
                        {info.products?.map((card, index) =>
                            <OrderDetailCard card={card} key={index}/>
                        )}
                        {info.promo_info?.type.slug === "min_price_additional_product" ?
                            info.promo_info.additional_products?.map((card, index) =>
                                <div className='popular-card' key={index}>
                                    <div className='overflow-hidden'>
                                        <img src={card.preview_image ? card.preview_image : img} alt={card.title} title={card.title}
                                            width={95}
                                            height={80}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                    </div>
                                    <div className='popular-card-content'>
                                        <h4>{card.title} X {card.count}</h4>
                                        <h4 className='price active-text'>{app.localizationsItems?.promo_product_content}</h4>
                                    </div>
                                </div>
                            )
                        : <></>
                        }
                    </div>
                </div>
                <div className='order-total-box'>
                    <h5 className='flex'>{app.localizationsItems?.sub_total} <span>{Utils.numberWithSpaces(info.sub_total_price)} {app.localizationsItems?.currency}</span></h5>
                    {info?.delivery_price ? 
                        <div className='flex'>{app.localizationsItems?.delivery_price} <span>{info.delivery_price} ₸</span></div> :
                        info?.promo_info?.type?.slug === "delivery" ? 
                        <div className='flex active-text'>{app.localizationsItems?.promo_delivery_content} <span>{app.localizationsItems?.free}</span></div> :
                        <div className='flex'>{app.localizationsItems?.delivery_price} <span>{info.delivery_price} ₸</span></div>
                    }
                    {info?.bonus_used ? <div className='flex'>{app.localizationsItems?.bonus_gained} <span>{info.bonus_used} ₸</span></div> : <></>}
                    {info?.discount_price ? <div className='flex active-text'>{app.localizationsItems?.discount_price} <span>-{info.discount_price} ₸</span></div> : <></>}
                    {info?.promo_info?.type?.slug === "min_price_discount" ? 
                        <div className='flex active-text'>{app.localizationsItems?.promo_discount_content} <span>-{info?.promo_info.discount} ₸</span></div> 
                    : <></>}

                    <h5 className='flex'>{app.localizationsItems?.total} <span>{Utils.numberWithSpaces(info.total_price)} {app.localizationsItems?.currency}</span></h5>
                    {info?.bonus_gained ? <div className='flex'>{app.localizationsItems?.bonuses} <span className='product-bonus'>{info.bonus_gained} ₸</span></div> : <></>}
                </div>
            </>
        }
    </div>
  )
})

export default OrderProductDetail