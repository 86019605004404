import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';

const Bonuses = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
    } = useFetchItems(UserServices.profile.balanceHistory)

  return (
    <div className='profile-dashboard'>
        <h4 className='mb-30'>{app.localizationsItems?.bonus_history}</h4>

        {loading ?
            <div className='loader-wrapper'>
                <span className='loader'/>
            </div>
            :
            <div>
                {items?.items?.length >= 1 ?
                    <div>
                        {items.items.map((card, index) =>
                            <div className='bonus-card' key={index}>
                                <div>
                                    <div className='title'>{app.localizationsItems?.order}: №{card.number}</div>
                                    <div className='date'>{card.created_at}</div>
                                    <NavLink to={`/${app.lang}/user/orders/${card.order_id}`}>{app.localizationsItems?.to_order}
                                        <i className='icon-chevron-right'></i>
                                    </NavLink>
                                </div>
                                <div className={card.value <= 0 ? 'bonus-state danger' : 'bonus-state'}>
                                    {card.value} ₸
                                </div>
                            </div>
                        )}
                    </div>
                :
                    <div><h5>{app.localizationsItems?.empty_data}</h5></div>
                }
            </div>
        }
    </div>
  )
})

export default Bonuses