import React, { useState } from 'react'
import Slider from 'react-slick'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/catalogServices';
import PopularProductCard from '../simple/PopularProductCard';
import ProductsLoading from '../simple/ProductsLoading';

const PopularProducts = ({elem}) => {

    const { 
        items,
        loading
    } = useFetchItems(CatalogServices.products.index, `type=${elem.products_type}&`);

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 996,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 757,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: "24px",
                
            }
        },

    ]
    }

  return (
    <>
        {loading ?
            <ProductsLoading/>
            :
            <div>
                <div className="container">
                    <h2 className='mb-30'>{elem.title}</h2>
                </div>
                {items?.items?.length >=1 && 
                    <div className="slider-container">
                        <Slider {...settings} className='recent-slider'>
                            {items.items.map((card, index) =>
                                <PopularProductCard card={card} key={index}/>
                            )}
                        </Slider>
                    </div>
                }
            </div>
        }
    </>
  )
}

export default PopularProducts