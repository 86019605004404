import React, { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap';
import CartProduct from '../simple/CartProduct';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CartSummary from '../simple/CartSummary';

const CartModal = observer(({show, setShow}) => {
    const {app, user} = useContext(Context)
    const handleClose = () => setShow(false);

  return (
      <Offcanvas show={show} onHide={handleClose} placement={'end'} className='cart-offcanvas'>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>{app.localizationsItems?.cart}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {user.cartProducts?.length >= 1 ?
                <div className='cart-container'>
                    {user.cartProducts?.map((card, index) =>
                        <CartProduct card={card} key={index}/>
                    )}
                    <CartSummary setShow={setShow}/>
                </div>
                :
                <h1></h1>
            }
        </Offcanvas.Body>
      </Offcanvas>
  )
})

export default CartModal