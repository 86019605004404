import React, { useEffect, useState } from 'react'
import PopupModal from '../modals/PopupModal';
import Utils from '../../services/utils';

const DownloadAppPopup = ({elem}) => {
    const [banner1, setBanner1] = useState();

    const [visible, setVisible] = useState();

    const [show, setShow] = useState();

    useEffect(() => {
        if(elem){
            setBanner1(elem.elements[0])
        }
    }, [elem])

    useEffect(() => {
        if(!Utils.getCookie('popup-modal')){
            setVisible(true);
        }
    }, [])

    const handleClose = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        Utils.setCookie('popup-modal', 'true', {secure: true, 'max-age': 86400});
        setVisible(false);
    }

  return (
    (banner1 && visible) &&
    <div className='download-popup-container'>
        <div className='download-popup' style={{backgroundImage: `url(${banner1.image})`}} onClick={() => setShow(true)}>
            <span className='download-close' onClick={handleClose}>
                <i className='icon-close'></i>
            </span>
            <div className='download-popup-title'>{banner1.title}</div>
            <div className='download-popup-description'>{banner1.description}</div>
        </div>
        {show && <PopupModal show={show} setShow={setShow} elem={elem} banner={banner1}/>}
    </div>
  )
}

export default DownloadAppPopup