import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Context } from '../..';

const ProfileTabsLink = observer(() => {
    const {app} = useContext(Context);
    
    const location = useLocation();

  return (
    <div className="col-xxl-3 col-lg-4">
        <div className="tab-links">
            <div className={(location.pathname === `/${app.lang}/user` || location.pathname === `/${app.lang}/user/dashboard`) ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/dashboard`}>
                    {app.localizationsItems?.dashboard}
                </NavLink>
            </div>

            <div className={((location.pathname === `/${app.lang}/user/orders`) || (location.pathname === `/${app.lang}/user/orders-history`)) ? 'tab-links-item active' : 'tab-links-item'} >
                <NavLink to={`/${app.lang}/user/orders`}>
                    {app.localizationsItems?.my_orders}
                </NavLink>
            </div>

            <div className={location.pathname === `/${app.lang}/user/bonuses` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/bonuses`}>
                    {app.localizationsItems?.bonuses}
                </NavLink>
            </div>
            
            <div className={location.pathname === `/${app.lang}/user/addresses` ? 'tab-links-item active' : 'tab-links-item'}>
                <NavLink to={`/${app.lang}/user/addresses`}>
                    {app.localizationsItems?.all_addresses}
                </NavLink>
            </div>
        </div>
    </div>
  )
})

export default ProfileTabsLink