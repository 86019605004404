import React from 'react'
import Slider from "react-slick";
import img from "../../images/placeholder.png"

const MainSlider = ({elem}) => {
    const settings = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode:true,
        variableWidth: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
        {
            breakpoint: 767,
            settings: {
                centerMode:false,
                variableWidth: false,
            }
        },
    ]
    }

  return (
    <div>
        <Slider {...settings} className='main-slider'>
            {elem.elements?.map((card, index) =>
                <div key={index} className='main-slider-item'>
                    <img src={card.front_image ? card.front_image : img} alt="" height={400} width={700}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                </div>
            )}
        </Slider>
    </div>
  )
}

export default MainSlider