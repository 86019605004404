import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..';
import img from "../../images/user.jpg"
import DeleteProfileModal from "../modals/DeleteProfileModal"
import EditProfileModal from '../modals/EditProfileModal';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthServices from '../../services/general/authServices';

const Dashboard = observer(() => {
    const {app, user} = useContext(Context);
    const [deleteModal, setDeleteModal] = useState();
    const [editModal, setEditModal] = useState();

    const navigate = useNavigate();

    const logOut = async () => {
        const res = await AuthServices.logOut();
        
        if (res.statusCode === 200) {
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
        }
    }

  return (
    <div className='profile-dashboard'>
        <div className="flex align-center mb-30">
            <h4>{app.localizationsItems?.profile}</h4>
            <NavLink to={`/${app.lang}/user/dashboard/edit`} className='span'>
                {app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </NavLink>
        </div>
        {user.user &&
            <div>
                <div className='profile-dashboard-wrapper'>
                    <img src={img} alt="" />
                    <div className='profile-dashboard-text'>
                        <div>
                            <span>{app.localizationsItems?.name}</span>
                            <p>{user.user.name  ? user.user.name : '-'}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.email}</span>
                            <p>{user.user.email ? user.user.email : '-'}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.phone}</span>
                            <p>{user.user.phone ? user.user.phone : '-'}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.birthday}</span>
                            <p>{user.user.date_of_birth ? user.user.date_of_birth : '-'}</p>
                        </div>
                        <div>
                            <span>{app.localizationsItems?.gender}</span>
                            <p>{user.user.gender === 'female' ? `${app.localizationsItems?.female}`: user.user.gender === 'male' ? `${app.localizationsItems?.male}` : '-'}</p>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div className='delete-container'>
            <div onClick={logOut}>{app.localizationsItems?.logout}</div>
            <span onClick={() => setDeleteModal(true)}>
                {app.localizationsItems?.delete_profile}
                <i className='icon-remove'></i>
            </span>
        </div>
        {deleteModal && <DeleteProfileModal show={deleteModal} setShow={setDeleteModal}/>}
        {editModal && <EditProfileModal show={editModal} setShow={setEditModal}/>}
    </div>
  )
})

export default Dashboard