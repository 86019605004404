import { observer } from 'mobx-react-lite';
import React, { useContext} from 'react'
import { Context } from '../..';
import { NavLink } from 'react-router-dom';
import SearchInput from '../ui/SearchInput';
import UserIcon from './UserIcon';

const TopBar = observer(({openMenu, handleOpen, handleClose, phone, logo}) => {
    const {app} = useContext(Context)
  return (
    <div className='top-bar'>
        <div className='container'>
            <div className='flex mobile-menu'> 
                <div className='flex'>
                    <NavLink to={'/'}>
                        {logo &&
                            <img src={logo.value} 
                                className='logo'
                                alt={logo.name} 
                                title={logo.name}
                                width={115}
                                height={42}
                            />
                        }
                    </NavLink>
                    <div className={openMenu ? 'menu-burger open' : 'menu-burger'} onClick={handleOpen}>
                        <i className='icon-burger'></i>
                    </div>
                </div>
                <div className='top-bar-wrapper'>
                    <SearchInput/>
                    <UserIcon handleClose={handleClose}/>
                    <div className='top-text'>
                        <div>{app.localizationsItems?.phone_call}</div>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default TopBar