import React, { useContext, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import CatalogServices from '../../services/general/catalogServices';
import { Context } from '../..';
import Utils from '../../services/utils';
import { useForm } from 'react-hook-form';
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import img from "../../images/placeholder.png"

const ProductViewModal = observer(({show, setShow, slug}) => {
    const {app, user} = useContext(Context);

    const [visible, setVisible] = useState(false);

    const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

    const [count, setCount] = useState(1);

    const {handleSubmit, register} = useForm();

    const handleClose = () => setShow(false);

    const customSubmit = async(data) => {
        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: info.id,
            count: count,
            fields: []
        }

        for(var key in data){
            newData.fields.push(
                {name: key, value: data[key]}
            )
        }
          
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            setVisible(true);
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
            toast.success('Товар добавлен в корзину')
            setShow(false)
        }else{
            toast.error(res.message)
        }

        setTimeout(() => {
            setVisible(false)
        }, 3000);
    }

    const plusCount = async () => {
        setCount(count+1);
    }

    const minusCount = async () => {
        if(count > 1){
            setCount(count-1);
        }
    }

  return (
    info &&
    <Modal show={show} onHide={handleClose} centered className='product-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form className='product-modal-wrapper' onSubmit={handleSubmit(customSubmit)}>
                <div className='position-relative'>
                    <img src={info.detail_image ? info.detail_image : img} alt={info.title} title={info.title}
                        width={320}
                        height={320}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                    <div className='modal-states'>
                        {info.state && 
                            <div className="product-state" style={{backgroundColor: `${info.state.subtitle}`}}>
                                <label>{info.state.name}</label>
                            </div>}
                        {info.bonus &&
                            <div className="product-bonus">
                                <label>{info.bonus.number} {info.bonus.type === 1 ? '%' : ''} Б</label>
                            </div>
                        }
                    </div>
                </div>
                <div className='product-modal-content'>
                    <div className='flex'>
                        <span>{info.parent?.title}</span>
                        {info.features && <Dropdown className='product-droupdown'>
                            <Dropdown.Toggle>
                                <i className='icon-info'></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                {info.features.map((card, index) =>
                                    <div className='feature' key={index}>
                                        {card.title}
                                        <span>{card.value}</span>
                                    </div>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                    <div className='availability'>
                        {app.localizationsItems?.availability}: {
                            info.availability ?
                                <span className="text-success">{app.localizationsItems?.in_stock}</span>
                            :
                                <span className="text-danger">{app.localizationsItems?.not_available}</span>
                        }
                    </div>
                    <h2>{info.title}</h2>
                    <p>{info.subtitle}</p>
                    {info.traits?.map((it, index) =>
                        it.title &&
                        <div key={index} className='product-input'>
                            <div className='product-title'> <h4>{it.title}</h4></div>
                            <div className='states-flex'>
                                {it.elements?.map((el, index) =>
                                    <label key={index} className='product-label'>
                                        <input  type="radio"  value={el.name} {...register(it.title)}/>
                                        <span>{el.name}</span>
                                    </label>
                                )}
                            </div>
                        </div>
                    )}
                    <div className='flex product-counter'>
                        <div className='input-count'>
                            <div className='input-btn' onClick={minusCount}>
                                <i className='icon-minus'></i>
                            </div>
                            <div className='value'>{count}</div>
                            <div className='input-btn' onClick={plusCount}>
                                <i className='icon-plus'></i>
                            </div>
                        </div>
                        <button className='main-btn blink-button'>{app.localizationsItems?.to_cart_for} {Utils.numberWithSpaces(info.price)} {app.localizationsItems?.currency} <span className='flare'></span></button>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default ProductViewModal