import React, { useContext, useEffect, useState } from 'react'
import LoginModal from '../modals/LoginModal';
import LoginCodeModal from '../modals/LoginCodeModal';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const UserIcon = observer(({handleClose}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();
    const [codeShow, setCodeShow] = useState();

    const [phone, setPhone] = useState();

    const navigate = useNavigate();

    const handleLogin = () => {
        setShow(true)
        handleClose();
    }

  return (
    <>
        {localStorage.getItem('token') ?
            <>
                {/*<button className='main-btn btn-outline'>
                    <i className='icon-gift'></i>
                    {app.localizationsItems?.stock}
  </button>*/}
                <div className='user-icon' onClick={() => {handleClose(); navigate(`/${app.lang}/user`)}}>
                    <i className='icon-user'></i>
                    {app.localizationsItems?.cabinet}
                </div>
            </>
            :
            <button className='main-btn btn-outline' onClick={handleLogin}>
                {app.localizationsItems?.login}
            </button>
        }
        {show && <LoginModal show={show} setShow={setShow} setCodeShow={setCodeShow} setPhone={setPhone}/>}
        {codeShow && <LoginCodeModal show={codeShow} setShow={setCodeShow} phone={phone}/>}
    </>
  )
})

export default UserIcon