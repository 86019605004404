import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import MapComponent2 from '../complex/MapComponent2';

const EditAddressModal = observer(({show, setShow, fetchItems, id, deliveryZones}) => {
    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.addresses.view, id);

    const [address, setAddress] = useState()

    const handleClose = () => setShow(false);

    const {handleSubmit, setValue, getValues, register, formState: {errors}} = useForm();

    useEffect(() => {
        if (info) {
            const {json_address} = info;
            for (const [key, value] of Object.entries(json_address)) {
                json_address[key] !== null && setValue(key, String(value));
            }
            info.full_address && setAddress(info.full_address)
        }
    }, [info, setValue])

    const CustomSubmit = async () => {
        const newData = {
            json_address: {
                entrance: getValues('entrance') ? getValues('entrance') : null,
                floor: getValues('floor') ? getValues('floor') : null,
                apartment: getValues('apartment') ? getValues('apartment') : null,
                code: getValues('code') ? getValues('code') : null,
                lat: address?.lat,
                lng: address?.lng
            },
            full_address: address?.address
        }

        const res = await UserServices.addresses.update(id, newData);

        if (res.statusCode === 200) {
            fetchItems();
            toast.success(app.localizationsItems?.address_success_update);
            setShow(false);
        }else{
            toast.error(res.message)
        }
    };

  return (
    <Modal show={show} onHide={handleClose} centered className='address-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className='address-modal-wrapper'>
                <div className='address-modal-container'>
                    <h2>{app.localizationsItems?.edit}</h2>
                    <div>
                        <div className='form-label'>
                            <label>{app.localizationsItems?.address}</label>
                            <div type="text" className="input input-center">
                                {address?.address}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-6">
                            <div className='form-label'>
                                    <label>{app.localizationsItems?.entrance}</label>
                                    <input type="text" className="input" {...register('entrance')} placeholder={app.localizationsItems?.entrance}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.floor}</label>
                                    <input type="text" className="input" {...register('floor')} placeholder={app.localizationsItems?.floor}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.apartment}</label>
                                    <input type="text" className="input" {...register('apartment')} placeholder={app.localizationsItems?.apartment}/>
                                </div> 
                            </div>
                            <div className="col-6">
                                <div className='form-label'>
                                    <label>{app.localizationsItems?.code_domofon}</label>
                                    <input type="text" className="input" {...register('code')} placeholder={app.localizationsItems?.code_domofon}/>
                                </div> 
                            </div>
                        </div>
                        <div className='main-btn' onClick={CustomSubmit} >{app.localizationsItems?.save}</div> 
                    </div>
                </div>
                <div className='map-container'>
                    <MapComponent2 setAddress={setAddress} info={info} deliveryZones={deliveryZones}/>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default EditAddressModal