import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import UserServices from '../../services/general/userServices';
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import ProfileTabs from '../../components/complex/ProfileTabs';
import Slider from "react-slick";

const Profile = observer(() => {
    const {app, user} = useContext(Context);

    const {info} = useFetchInfo(UserServices.profile.index);

    useEffect(() => {
        if(info){
            user.setUser(info)
        }
    }, [info])

    const settings = {
        arrows: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

  return (
    <div className='profile'>
        <div className="container">
            {info &&
                <div className='mb-30'>
                    <div className="col-12 mb-30">
                        <h1>{app.localizationsItems?.welcome_profile} {info.name}</h1>
                    </div>
                    <Slider {...settings} className='profile-slider'>
                        <div>
                            <NavLink to={`/${app.lang}/user/bonuses`} className='profile-card profile-card1'>
                                <div className='profile-card-title'>{app.localizationsItems?.bonuses}</div>
                                <div className='profile-card-price'>{info.balance}</div>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/${app.lang}/user/orders`} className='profile-card profile-card2'>
                                <div className='profile-card-title'>{app.localizationsItems?.my_orders}</div>
                                <div className='profile-card-price'>{info.orders_count}</div>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/${app.lang}/user/addresses`} className='profile-card profile-card3'>
                                <div className='profile-card-title'>{app.localizationsItems?.all_addresses}</div>
                                <div className='profile-card-price'>{info.addresses_count}</div>
                            </NavLink>
                        </div>
                    </Slider>
                </div>
            }
            <ProfileTabs/>
        </div>
    </div>
  )
})

export default Profile