import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../..';
import { Modal } from 'react-bootstrap'
import UserServices from '../../services/general/userServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DeleteProfileModal = observer(({show, setShow}) => {
    const {user, app} = useContext(Context);
    const handleClose = () => setShow(false);

    const navigate = useNavigate();

    const handleDelete = async () => {
        const res = await UserServices.profile.delete();

        if(res?.statusCode === 200){
            user.setUser({})
            user.removeToken()
            user.setBasketChange(Math.random().toString(16));
            navigate(`/${app.lang}`);
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='delete-modal'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className="delete-profile-modal">
                <h3>{app.localizationsItems?.delete_profile_content}</h3>
                <div className="row">
                    <div className="col-6">
                        <button className='main-btn btn-gray'>{app.localizationsItems?.cancel}</button>  
                    </div>
                    <div className="col-6">
                        <button className='main-btn' onClick={handleDelete}>{app.localizationsItems?.delete}</button>  
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteProfileModal