import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProfileTabsLink from './ProfileTabsLink';
import Orders from './Orders';
import Dashboard from './Dashboard';
import Bonuses from './Bonuses';
import Address from './Address';
import OrderProductDetail from './OrderProductDetail';
import OrderHistory from './OrderHistory';
import DashboardEdit from './DashboardEdit';

const ProfileTabs = () => {
  return (
    <div className="row">
        <ProfileTabsLink/>
        <div className="col-xxl-9 col-lg-8">
                <div className="dashboard-right-sidebar">
                    <Routes>
                        <Route index element={<Dashboard />} />
                        <Route path='dashboard' element={<Dashboard/>}/>
                        <Route path='dashboard/edit' element={<DashboardEdit/>}/>
                        <Route path='orders' element={<Orders/>}/>
                        <Route path='bonuses' element={<Bonuses/>}/>
                        <Route path='addresses' element={<Address/>}/>
                        <Route path='orders-history' element={<OrderHistory/>}/>
                        <Route path='orders/:id' element={<OrderProductDetail/>}/>
                    </Routes>
                </div>
        </div>
    </div>
  )
}

export default ProfileTabs