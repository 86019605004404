import React, { useEffect, useState } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import ProductCard from '../simple/ProductCard';
import SkeletonLoading from '../simple/SkeletonLoading';

const CategoryProducts = ({item, length, index, loading, setLoading}) => {
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        let str = `category_id=${item.id}`;

        const res = await CatalogServices.products.index(`${str}&per_page=all`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }

        if(index === length-1){
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [])

  return (
    <>
        {loading ?
            <SkeletonLoading/>
            :
            <div className='mb-50 category-item' id={item.slug}>
                <h2 className='mb-30'>{item.title}</h2>
                    <div className='grid'>
                        {items?.map((card, index) =>
                            <ProductCard card={card} key={index}/>
                        )}
                </div>
            </div>
        }
    </>
  )
}

export default CategoryProducts