import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import CartModal from '../modals/CartModal'
import UserServices from '../../services/general/userServices'
import { toast } from 'react-toastify'
import ScrollCategories from './ScrollCategories'

const Menu = observer(() => {
    const {app, user} = useContext(Context);

    const [logo, setLogo] = useState();
    const [show, setShow] = useState();
    const [sticky, setStiky] = useState();

    useEffect(() => {
        if(app.settings.length >= 1){
            setLogo(app.settings.find(it => it.type === "logo")?.value);
        }
    }, [app.settings])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleShow = () => {
        if(user.cartProducts?.length >= 1){
            setShow(true)
        }else{
            toast.warning('Корзина пуста')
        }
    };

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            res.content?.products ? user.setCartProducts(res.content.products) : user.setCartProducts([])
            res.content?.total_price ? user.setTotalSum(res.content.total_price) : user.setTotalSum(0)

            if(res.content?.uid && (res.content?.uid !== user.uid)){
                user.setUid(res.content?.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems();
    }, [user.basketChange])

  return (
    <div className={sticky ? 'menu sticky' : 'menu'}>
        <div className="container">
            <div className="flex">
                <ScrollCategories logo={logo}/>
                <button className='main-btn btn-cart' onClick={handleShow}>
                    <i className='icon-cart'></i>
                    <div className='vis-none'>
                        {app.localizationsItems?.cart} <span className='count'>|</span>
                    </div>
                    <span className='count count-icon'>{user.cartProducts?.length ? user?.cartProducts?.length : 0}</span>
                </button>
            </div>
        </div>
        {show && <CartModal show={show} setShow={setShow}/>}
    </div>
  )
})

export default Menu