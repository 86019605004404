import React, { useContext, useState } from 'react'
import ProductViewModal from '../modals/ProductViewModal'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"

const PopularProductCard = observer(({card}) => {
    const {app} = useContext(Context);
    const [show, setShow] = useState();

  return (
    <>
        <div className='popular-card' onClick={() => setShow(true)}>
            <div className='overflow-hidden'>
                <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            </div>
            <div className='popular-card-content'>
                <h4>{card.title}</h4>
                {card.old_price && <div className='old-price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</div>}
                <h4 className='price'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h4>
            </div>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug}/>}
    </>
  )
})

export default PopularProductCard