import React, { useContext, useEffect, useState } from 'react'
import Utils from '../../services/utils'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import ProductViewModal from '../modals/ProductViewModal';
import UserServices from '../../services/general/userServices';
import img from "../../images/placeholder.png"

const ProductCard = observer(({card}) => {
    const {app, user} = useContext(Context);

    const [show, setShow] = useState();

    const [isFavorite, setIsFavorite] = useState();

    useEffect(() => {
        if(user.favorites.find(it => it ===card.id)){
            setIsFavorite(true);
        }else{
            setIsFavorite(false);
        }
    }, [user.favorites])

    const addToWishlist = async (e) => {
        e.stopPropagation();
        let temp = isFavorite;
        setIsFavorite(!isFavorite);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: card.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
            user.setWishlistCountChange(Math.random().toString(16))
        }else{
            setIsFavorite(temp)
        }
    }

  return (
    <div className='product-card'>
        <div className='card-relative' onClick={() => setShow(true)}>
            <span className='to-wishlist'  onClick={e =>addToWishlist(e)}>
                <i className={isFavorite ? 'icon-like active' : 'icon-heart'}></i>
            </span>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={266}
                height={225}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='modal-states'>
                {card.state && <div className="product-state" style={{backgroundColor: `${card.state.subtitle}`}}>
                    <label>{card.state.name}</label>
                </div>}
                {card.bonus && <div className="product-bonus">
                    <label>{card.bonus.number}{card.bonus.type === 1 ? '%' : ''} Б</label>
                </div>}
            </div>
        </div>
        <div className='product-flex'>
            <div className='product-card-wrapper' onClick={() => setShow(true)}>
                <h4>{card.title}</h4>
                <p>{card.subtitle}</p>
            </div>
            <div className='product-card-content'>
                <h4>
                    <span className='price'>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} </span>
                    {card.old_price && <span className='old-price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
                </h4>
                <button className='main-btn btn-sm btn-icon' onClick={() => setShow(true)}>
                    {app.localizationsItems?.to_cart}
                </button>
            </div>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug}/>}
    </div>
  )
})

export default ProductCard