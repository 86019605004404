import React, { useContext, useEffect } from 'react'
import AppServices from '../../services/general/appServices';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../..'
import useFetchItems from '../../hooks/useFetchItems';
import MainSlider from '../../components/complex/MainSlider';
import { observer } from 'mobx-react-lite';
import Products from '../../components/complex/Products';
import PopularProducts from '../../components/complex/PopularProducts';
import DownloadAppPopup from '../../components/complex/DownloadAppPopup';
import StoriesComponent from '../../components/complex/StoriesComponent';
import PageLoading from '../../components/simple/PageLoading';

const MainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')
    
  return (
    <>
        {
            loading ?
                <PageLoading/>
            :
            <div>
                {items &&
                    items.elements?.map((elem, index) =>
                        <div key={index} className='mb-50'>
                            {elem.type === "opening-slider" ?
                                <MainSlider elem={elem}/>
                                :
                            elem.type === "stories" ?
                                <StoriesComponent elem={elem}/>
                                :
                            elem.type === "advertising-banner-2" ?
                                <DownloadAppPopup elem={elem}/>
                                :
                            elem.type === "products" ?
                                elem.products_type === "recommended" ?
                                    <PopularProducts elem={elem}/>
                                    :
                                    <Products elem={elem}/>
                                :
                                <></>
                            }
                        </div>
                    )
                }
            </div>
        }
    </>
  )
})

export default MainPage