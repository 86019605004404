import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Context } from '../..';
import Utils from '../../services/utils';
import UserServices from '../../services/general/userServices';

const CartSummary = observer(({setShow}) => {
    const {app, user} = useContext(Context);

    const [total, setTotal] = useState(0);
    const [bonuses, setBonuses] = useState();
    const [discount, setDiscount] = useState();
    const [subTotal, setSubTotal] = useState(0);

    const [bonuseUse, setBonusUse] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if(user.cartProducts){
            let temp = 0;
            let temp2 = 0;
            let temp3 = 0;
            for(let i = 0; i < user.cartProducts.length; i++){
                temp += Number(user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                temp2 += Number(user.cartProducts[i]?.old_price ?  user.cartProducts[i]?.old_price : user.cartProducts[i]?.price) * Number(user.cartProducts[i].count);
                if(user.cartProducts[i].bonus?.type == 1){
                    temp3 += (Math.floor((Number(user.cartProducts[i].price) * Number(user.cartProducts[i].bonus.number))/100) * Number(user.cartProducts[i].count))
                }else if(user.cartProducts[i].bonus){
                    temp3 += (Math.floor(Number(user.cartProducts[i].bonus?.number) * Number(user.cartProducts[i]?.count)))
                }
            }
            setTotal(temp);
            setSubTotal(temp2);
            if(temp3 && temp3 > 0){
                setBonuses(temp3);
            }
            if(temp !== temp2){
                setDiscount(Number(temp2)-Number(temp))
            }else{
                setDiscount(null)
            }
        }
    }, [user.cartProducts])

    const handleNavigate = () => {
        navigate(`/${app.lang}/checkout`);
        setShow(false);
    }

    const userProfile = async () => {
        const res = await UserServices.profile.index();
        if(res.statusCode === 200){
            user.setBalance(res.content.balance);
        }
    }

    useEffect(() => {
        if(user.token){
            userProfile();
        }
    }, [])

    const handleUseBonuses = (e) => {
        setBonusUse(!bonuseUse);
    }

  return (
    <div className='cart-summary'>
        <div className='flex pd-12'>
            <h5>{app.localizationsItems?.sub_total}</h5> <h5>{Utils.numberWithSpaces(subTotal)} {app.localizationsItems?.currency}</h5>
        </div>
        {/*<div className='input-flex pd-12'>
            <input type="text" placeholder='Прококод' className='input'/>
            <button className='main-btn btn-outline'>{app.localizationsItems?.apply}</button>
  </div>*/}
        {user.balance ? 
            <div className='bonus-switcher'>
                <div className='text'>
                    <div>{app.localizationsItems?.spend_bonuses}</div>
                    <span>{Utils.numberWithSpaces(user.balance)} {app.localizationsItems?.bonuses_accumulated}</span>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" value={bonuseUse} onChange={ e => handleUseBonuses(e)}/>
                </div>
            </div> : <></>
        }
        {(bonuseUse || discount) &&
            <div className='cart-summary-wrapper'>
                {bonuseUse && <div className='list-item'>{app.localizationsItems?.used_bonuses}<span>- {Utils.numberWithSpaces(user.balance)} {app.localizationsItems?.currency}</span></div>}
                {discount && <div className='list-item'>{app.localizationsItems?.discount_price}<span className='discount'>- {Utils.numberWithSpaces(discount)} {app.localizationsItems?.currency}</span></div>}
            </div>
        }
        <div className='pd-12 item-bottom'>
            <div className='flex'>
                <h3>{app.localizationsItems?.total}</h3> <h3>{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</h3>
            </div>
            {bonuses && <div className='list-item'>{app.localizationsItems?.bonuses} <span className="product-bonus"> + {Utils.numberWithSpaces(bonuses)} Б</span> </div>}
            <button onClick={handleNavigate} className='main-btn blink-button'>{app.localizationsItems?.checkout_order} <span className='flare'></span></button>
        </div>
    </div>
  )
})

export default CartSummary