import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { observer } from 'mobx-react-lite'
import DroupdownLang from '../ui/DroupdownLang'
import UserIcon from './UserIcon'
import { Context } from '../..'

const TopNavigation = observer(({openMenu, phone, handleClose}) => {
    const {app} = useContext(Context);

    const {
        items,
    } = useFetchItems(AppServices.menu, 'top-menu')

  return (
    <div className={openMenu ? 'top-navigation open' : 'top-navigation'}>
        <span className='close-button' onClick={handleClose}>
            <i className='icon-close'></i>
        </span>
        <div className="container">
            <div className="flex">
                <DroupdownLang/>
                {items?.length >= 1 && <nav className='top-nav'>
                    {items.map((card, index) =>
                        <NavLink to={card.value} key={index} target={card.type} onClick={handleClose}>{card.title}</NavLink>
                    )}
                </nav>}
                <div className='mob-user'>
                    <UserIcon handleClose={handleClose}/>
                    <div className='top-text'>
                        <div>{app.localizationsItems?.phone_call}</div>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default TopNavigation