import React, { useContext, useState } from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import ProductViewModal from '../modals/ProductViewModal'
import img from "../../images/placeholder.png"

const SearchProduct = observer(({card}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState();

  return (
    <>
        <div className='search-product' onClick={() => setShow(true)}>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={60}
                height={60}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='search-product-content'>
                <p>{card.title}</p>
                <p>{Utils.numberWithSpaces(card.price)} {app.localizationsItems.currency} 
                    {card.old_price &&<span className='old-price'> {Utils.numberWithSpaces(card.old_price)} {app.localizationsItems.currency}</span>}
                </p>
            </div>
        </div>
        {show && <ProductViewModal show={show} setShow={setShow} slug={card.slug}/>}
    </>
  )
})

export default SearchProduct