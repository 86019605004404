import React, { useEffect, useState } from 'react'
import StoryItems from '../complex/StoryItems';

const StoryModal = ({elem, setShow, currentStory, setVisited}) => {
    const [activeSlider, setActiveSlider] = useState(currentStory);
    const [sliderLengt, setSliderLength] = useState();

    useEffect(() => {
        if(elem?.elements){
            let names = localStorage.getItem("stories_opened") ? JSON.parse(localStorage.getItem("stories_opened")) : [];
            const card = elem.elements[activeSlider]?.id

            if(!names.find(it => it === card)){
                names.push(card);
            }
            setVisited(names)
            localStorage.setItem('stories_opened', JSON.stringify(names));
        }
    }, [activeSlider])

    useEffect(() => {
        if(elem?.elements){
            setSliderLength(elem.elements.length)
        }
    }, [elem])

    const handleNext = () => {
        if(activeSlider+1 === sliderLengt){
            setActiveSlider(0)
        }else{
            setActiveSlider(activeSlider+1)
        }
    }
    const handlePrev = () => {
        if(activeSlider === 0){
            setActiveSlider(sliderLengt-1)
        }else{
            setActiveSlider(activeSlider-1)
        }
    }

    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShow(false);
    }

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

  return (
    <div className='story-modal' onClick={e => handleClose(e)}>
        <div className='story-close' onClick={e => handleClose(e)}>
            <i className='icon-close'></i>
        </div>
        <div className="story-modal-content" onClick={e => handleClick(e)}>
            <div className='story-button left' onClick={handlePrev}>
                <i className='icon-chevron-left'></i>
            </div>
            {elem.elements?.map((card, index) =>
                card.items && activeSlider === index &&
                    <div className={activeSlider === index ? 'story-content-card active' : 'story-content-card'} key={index}>
                        <StoryItems card={card} setActiveSlider={setActiveSlider} activeSlider={activeSlider} sliderLengt={sliderLengt}/>
                    </div>
            )}
            <div className='story-button right' onClick={handleNext}>
                <i className='icon-chevron-right'></i>
            </div>
        </div>
    </div>
  )
}

export default StoryModal