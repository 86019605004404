import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './navigation/AppRouter'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './App.scss';
import Header from './components/complex/Header';
import { Context } from '.';
import AppServices from './services/general/appServices';
import UserServices from './services/general/userServices';
import './i18n/i18n'
import { observer } from 'mobx-react-lite';
import Footer from './components/complex/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './hooks/scrollToTop';
import PageLoading from './components/simple/PageLoading';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                
            }else{
                user.removeToken();
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;

            app.setSettings(settings);

            let mainColor = settings.find(it => it.type === "color-primary")?.value;
            let accentColor = settings.find(it => it.type === "accent-color")?.value;
            let mainGradient = settings.find(it => it.type === "main-gradient")?.value;
            let bonusColor = settings.find(it => it.type === "bonus-color")?.value;
            let profileCard = settings.find(it => it.type === "profile-card")?.value;
            let profileItemColor = settings.find(it => it.type === "profile-item-color")?.value;
            let notificationFontColor = settings.find(it => it.type === "notification-font-color")?.value;
            let notificationColor = settings.find(it => it.type === "notification-color")?.value;

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;

            mainColor && document.documentElement.style.setProperty('--mainColor', mainColor);
            accentColor && document.documentElement.style.setProperty('--accentColor', accentColor);
            mainGradient && document.documentElement.style.setProperty('--mainGradient', mainGradient);
            bonusColor && document.documentElement.style.setProperty('--bonusColor', bonusColor);
            profileCard && document.documentElement.style.setProperty('--profileCard', profileCard);
            profileItemColor && document.documentElement.style.setProperty('--profileItemColor', profileItemColor);
            notificationColor && document.documentElement.style.setProperty('--notificationColor', notificationColor);
            notificationFontColor && document.documentElement.style.setProperty('--notificationFontColor', notificationFontColor);

            let mainFont = settings.find(it => it.type === "font-primary")?.value;
            let secondaryFont = settings.find(it => it.type === "font-secondary")?.value;

            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }

            if(mainFont){
                let elements = mainFont.elements
                for(let i =0; i<elements.length; i++){
                  const font_name = new FontFace(mainFont.font_name, `url(${elements[i].link})`, {
                    style: elements[i].type,
                    weight: elements[i].value
                  });
                  document.fonts.add(font_name);
                }
                document.documentElement.style.setProperty('--fontFamily', `${mainFont.font_name}, sans-serif`)
            }
            if(secondaryFont){
                let elements = secondaryFont.elements
                for(let i =0; i<elements.length; i++){
                  const font_name = new FontFace(secondaryFont.font_name, `url(${elements[i].link})`, {
                    style: elements[i].type,
                    weight: elements[i].value
                  });
                  document.fonts.add(font_name);
                }
                document.documentElement.style.setProperty('--fontFamily2', `${secondaryFont.font_name}, sans-serif`)
            }
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp);

            let localizations = res2.content.find(it => it.slug === temp)?.description;

            let map2 = app.localizationsItems;
            for (let key in localizations) {
                map2[key] = localizations[key];
            }
            app.setLocalizationsItems(map2)

            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
        checkAuth();
    }, [app.lang])

  return (
    <>  
        {
            loading ?
                <PageLoading/>
            :
                <BrowserRouter>
                    <ScrollToTop />
                    <Header/>
                    <AppRouter/>
                    <Footer/>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </BrowserRouter>
        }
    </>
  )
})

export default App