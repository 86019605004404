import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import CategoryProducts from './CategoryProducts'
import UserServices from '../../services/general/userServices'
import ProductCard from '../simple/ProductCard'
import MobCategoryScrollMenu from './MobCategoryScrollMenu'

const Products = observer(({elem}) => {
    const {app, user, catalog} = useContext(Context);
    const componentRef = useRef(null);
    const [isFixed, setIsFixed] = useState(false);

    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);

    const fetchItems = async () => {
        const res = user.wishlistUid ? await UserServices.favorites.index(user.wishlistUid) : await UserServices.favorites.index();

        if(res.statusCode === 200 && res.content){
            const favoritesIds = res.content.items?.map((it, index) =>{
                return it.id
            })
            favoritesIds && user.setFavorites(favoritesIds)
            res.content.items && setItems(res.content.items)

            if(res.content.uid && (res.content.uid !== user.wishlistUid)){
                user.setUid(res.content?.uid)
            }
        }
    }
    
    useEffect(() => {
        if(user.wishlistUid || user.token){
            fetchItems();
        }
    }, [user.wishlistCountChange])
    
    const handleScroll = () => {
        if (componentRef.current) {
          const { top } = componentRef.current.getBoundingClientRect();
          setIsFixed(top <= 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


  return (
    <div className='container' ref={componentRef}>
        <MobCategoryScrollMenu isFixed={isFixed}/>
        {items?.length >= 1 ? <div className='mb-50 category-item' id={'favorites'}>
            <h2 className='mb-30'>{app.localizationsItems?.wishlist}</h2>
            <div className='grid'>
                {items.map((card, index) =>
                    <ProductCard card={card} key={card.id}/>
                )}
            </div>
        </div> : <></>}
        {catalog.categories?.map((item, index) =>
            <CategoryProducts key={index} item={item} length={catalog.categories.length} index={index} loading={loading} setLoading={setLoading}/>
        )}
    </div>
  )
})

export default Products