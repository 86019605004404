import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import OrderCard from './OrderCard';

const ActiveOrders = observer(() => {
    const {app, catalog} = useContext(Context);

    const {
        items,
        loading
    } = useFetchItems(UserServices.orders.index)

  return (
    <>
        {loading ?
            <div className='loader-wrapper'>
                <span className='loader'/>
            </div>
            :
            <div>
                {items?.items?.length >= 1 ?
                    <div>
                        {items.items.map((card, index) =>
                            <OrderCard card={card} key={index} statuses={catalog.statuses}/>
                        )}
                    </div>
                    :
                    <div>
                        <h4>{app.localizationsItems?.no_active_orders}</h4>
                    </div>
                }
            </div>
        }
    </>
  )
})

export default ActiveOrders