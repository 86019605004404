import React, { useEffect, useRef, useState } from 'react'

const StoryItems = ({card, setActiveSlider, activeSlider, sliderLengt}) => {
    const [activeStory, setActiveStory] = useState(0);
    const [itemsLength, setItemsLength] = useState(card.items.length);

    const [progress, setProgress] = useState(0);

    const autoSwitchInterval = useRef(null);
    const progressRef = useRef(null);
    
    useEffect(() => {
        autoSwitchInterval.current =  setTimeout(() => {
            if (activeStory < itemsLength - 1) {
                setActiveStory(activeStory + 1);
            } else {
                if(activeSlider+1 === sliderLengt){
                    setActiveSlider(0);
                }else{
                    setActiveSlider(activeSlider+1)
                }
            }
        }, 5000);
    
        return () => clearTimeout(autoSwitchInterval.current);
    }, [activeSlider, activeStory])

    useEffect(() => {
        progressRef.current = setInterval(() => {
            setProgress(prevProgress => {
                const newProgress = prevProgress + 2.5;

                if (newProgress >= 100) {
                    clearInterval(progressRef.current);
                    
                }
                return newProgress;
            });
        }, 100);
    
        return () => clearInterval(progressRef.current);
        
      }, [activeStory]);

    const nextStory = () => {
        clearInterval(progressRef.current);
        setProgress(0)
        if(activeStory+1 === itemsLength){
            if(activeSlider+1 === sliderLengt){
                setActiveSlider(0);
            }else{
                setActiveSlider(activeSlider+1)
            }
        }else{
            setActiveStory(activeStory+1)
        }
    }

    const prevStory = () => {
        clearInterval(progressRef.current);
        setProgress(0)
        if(activeStory === 0){
            if(activeSlider === 0){
                setActiveSlider(sliderLengt-1)
                setActiveStory(0)
            }else{
                setActiveSlider(activeSlider-1)
            }
        }else{
            setActiveStory(activeStory-1)  
        }
    }

    const handleMouseDown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        clearInterval(autoSwitchInterval.current);
        clearInterval(progressRef.current);
    }

  return (
    <>
        <div className='story-range'>
            {card.items?.map((pr, index) =>
                <div className='story-range-item' key={index}>
                    <div className='progress' style={{width: activeStory === index && `${progress}%`}}></div>
                </div>
            )}
        </div>
        <div className='story-modal-stories' onMouseDown={e => handleMouseDown(e)}>
            {card.items?.map((it, index) => 
                <div key={index} className={activeStory === index ? 'story-modal-item active' : 'story-modal-item'}>
                    <div className='img-left' onClick={prevStory}></div>
                    <img src={it.image} alt="" />
                    <div className='img-right' onClick={nextStory}></div>
                </div>
            )}
        </div>
    </>
  )
}

export default StoryItems