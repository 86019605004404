import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';

const OrderCard = observer(({card, statuses}) => {
    const {app} = useContext(Context);

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        statuses.map((el, index)=>{
            card.status?.slug === el.slug && setActiveIndex(index)
        })
    },[])

  return (
    <NavLink to={`/${app.lang}/user/orders/${card.id}`}>
        <div className='order-card'>
            {statuses && 
                <div className='order-statuses'>
                    {statuses.map((el, index)=>
                        <div className={((index === activeIndex) || (index < activeIndex)) ? 'order-statuses-item active' : 'order-statuses-item'} key={index}>
                            <span>{el.name}</span>
                        </div>
                    )}
                </div>
            }
            <div className='order-wrapper'>
                <div>
                    <div className='order-text'>{card.address?.full_address}</div>
                    <div>{app.localizationsItems?.order}: №{card.number}</div>
                    <div>{card.created_at}</div>
                </div>
                <div>
                    <div className='order-price'>{Utils.numberWithSpaces(card.total_price)} {app.localizationsItems?.currency}</div>
                    {card.products &&
                        <div className='order-products'>
                            {card.products.slice(0, 2).map((el, index) =>
                                <img src={el.image} alt="" key={index}/>
                            )}
                            {card.products.length >= 3 && <div className='order-card-box'>+{Number(card.products.length) - 2}</div>}
                        </div>
                    }
                </div>
            </div>
        </div>
    </NavLink>
  )
})

export default OrderCard