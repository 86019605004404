import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import UserServices from '../../services/general/userServices';
import ProfileAddressCard from '../simple/ProfileAddressCard';
import AddAddressModal from '../modals/AddAddressModal';

const Address = observer(() => {
    const {app} = useContext(Context);

    const [modalOpen, setModalOpen] = useState(false);

    const handleShow = () => setModalOpen(true);

    const {
        items,
        fetchItems,
        loading
    } = useFetchItems(UserServices.addresses.index)

  return (
    <div className='profile-dashboard'>
        <div className='flex mb-30'>
            <h4>{app.localizationsItems?.all_addresses}</h4>
            <div className='red' onClick={handleShow}>+ {app.localizationsItems?.add_address}</div>
        </div>
        {loading ?
            <div className='loader-wrapper'>
                <span className='loader'/>
            </div>
            :
            <>
                {items?.items?.length >= 1 ?
                    <div className="address-grid">
                        {items.items.map((card, index) =>
                            <ProfileAddressCard card={card} fetchItems={fetchItems} key={index}/>
                        )}
                    </div>
                    :
                    <div><h5>{app.localizationsItems?.empty_data}</h5></div>
                }
            </>
        }
        {modalOpen && <AddAddressModal show={modalOpen} setShow={setModalOpen} fetchItems={fetchItems}/>}
    </div>
  )
})

export default Address