import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import AuthServices from '../../services/general/authServices';

const LoginModal = observer(({show, setShow, setCodeShow, setPhone}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();
    
    const handleClose = () => setShow(false);

    const {handleSubmit, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            setCodeShow(true);
            setPhone(data.phone);
            setShow(false);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered className='login-modal-container'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <form className='login-modal' onSubmit={handleSubmit(CustomSubmit)}>
                {loading &&
                    <div className='loader-wrapper-absolute'> <span className="loader"></span> </div>
                }
                <h2>{app.localizationsItems?.entry}</h2>
                <p>{app.localizationsItems?.login_content}</p>

                <div className='form-label'>
                    <label>{app.localizationsItems?.phone}*</label>
                    <Controller defaultValue="" name="phone" control={control}
                        render={({field: {onChange, value}}) => (
                            <ReactInputMask onChange={onChange} value={value}
                                mask="+7(999)999-99-99" maskChar="_">
                                {
                                    inputProps => (
                                        <input type="tel" className="input" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                    )
                                }
                            </ReactInputMask>
                        )}
                        rules={{pattern: {
                            value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                        }}}
                    />
                </div>
                <button className='main-btn'>{app.localizationsItems?.get_code}</button>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default LoginModal