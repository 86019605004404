import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../..';
import { Modal } from 'react-bootstrap'

const PopupModal = observer(({show, setShow, elem, banner}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='popup-modal-content'>
        <div className='close' onClick={handleClose}>
            <i className='icon-close'></i>
        </div>
        <Modal.Body>
            <div className="popup-modal" style={{backgroundImage: `url(${banner.mob_image})`}}>
                <div>
                    <h3>{elem.title}</h3>
                    <p className='subtitle'>{elem.subtitle}</p>
                    <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
                    <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.thanks}</button>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default PopupModal