import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const NotificationTop = observer(() => {
    const {app} = useContext(Context);
    const [visible, setVisible] = useState();

    useEffect(() => {
        const work_schedule = app.settings.find(it => it.type === "work_schedule")?.value;
        if(work_schedule){
            if(work_schedule.not_accept_orders){
                setVisible(true)
            }else{
                const nowdate = new Date();
                const dayIndex = nowdate.getDay();
                const dayName = daysOfWeek[dayIndex];
                const time = `${nowdate.getHours()}:${nowdate.getMinutes()}`;
                const schedule = work_schedule.week[dayName];
        
                if(schedule && schedule.end && schedule.begin){
                    const [hours, minutes] = time.split(':').map(Number);
                    const timeMinutes = hours * 60 + minutes;
                    const [startHours, startMinutes] = schedule.begin.split(':').map(Number);
                    const time2Minutes = startHours * 60 + startMinutes;
                    const [endHours, endMinutes] = schedule.end.split(':').map(Number);
                    const time3Minutes = endHours * 60 + endMinutes;
        
                    setVisible(!(time2Minutes <= timeMinutes && timeMinutes <= time3Minutes));
                }
            }
        }
    }, [app.settings]);

  return (
    visible &&
    <div className='notification-top'>
        <div className="container">
            <div className='notification-content'>
                <i className='icon-info'></i>
                {app.localizationsItems?.not_accent_orders}
            </div>
        </div>
    </div>
  )
})

export default NotificationTop