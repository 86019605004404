import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"

const OrderDetailCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <div className='popular-card'>
        <div className='overflow-hidden'>
            <img src={card.image ? card.image : img} alt={card.title} title={card.title}
                width={95}
                height={80}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            {card.bonus_gained && <span className='bonus-gained'>
                {card.bonus_gained} Б
            </span>}
        </div>
        <div className='popular-card-content'>
            <h4>{card.title} X {card.count}</h4>
            <h4 className='price'>{card.old_price && <span className='old-price'>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>} {Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h4>
        </div>
    </div>
  )
})

export default OrderDetailCard